<template>
  <!-- 图标选择组件 -->
  <div class="IconSelect">
    <!-- 选中默认图标 -->
    <div class="flex">
      <div class="icon-wrap" @click="show = !show" style="margin: 0 15px 0 0">
        <i class="custom-icon" :class="'custom-icon-' + mValue"></i>
      </div>

      <!-- 选中高亮图标 -->
      <div class="icon-wrap" @click="show = !show">
        <i
          class="custom-icon"
          :class="'custom-icon-' + mValue"
          :style="{color: tabColor}"
        ></i>
      </div>
    </div>

    <!-- 选择图标弹窗 -->
    <el-dialog title="选择图标" :visible.sync="show" width="30%">
      <ul class="flex flex-wrap">
        <li
          v-for="icon in iconList"
          :key="icon"
          class="icon-wrap"
          :class="[mValue == icon ? 'icon-wrap-active' : '']"
          style="margin: 5px 10px"
          @click="mValue = icon"
        >
          <i class="custom-icon" :class="'custom-icon-' + icon"></i>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "IconSelect",

  props: ["value"],

  data() {
    return {
      show: false,
      iconList: [
        "home",
        "shopping-cart",
        "level",
        "hourglass",
        "order",
        "list",
        "account",
        "photo",
        "list-dot",
        "integral",
        "coupon",
        "bag",
      ],
      mValue: "",
    };
  },
  computed: {
    ...mapGetters(["project"]),

    tabColor() {
      return this.project.config.navigation.styles.color;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        this.mValue = newValue;
      },
    },

    mValue: {
      immediate: true,
      handler(newValue, oldValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  border: 1px solid #e3e2e5;
  border-radius: 4px;
  cursor: pointer;

  i {
    color: #7d7d7d;
    font-size: 36px;
  }
}

.icon-wrap-active {
  border: 1px solid $color-theme;
}
</style>
